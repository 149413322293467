import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BackgroundSlider from 'react-background-slider';

// Images
import BgImage from '~images/banner/single-image.jpg';
import SingleWelcome from '~images/banner/single-welcome.png';

const SingleImage = () => {
    return (
        <section id="home" className="banner image-bg">

            <BackgroundSlider
                duration={100000}
                transition={0.75}
                className="image-bg"
                images={[BgImage]}
            />

			{/* <!-- Container --> */}
			<Container>

				<Row className="align-items-center">

					{/* <!-- Content --> */}
					<Col className="col-12 col-lg-6 res-margin">

						{/* <!-- Banner text --> */}
						<div className="banner-text">

							<h1 className="wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0s">
								Bridging the Universe<br />with the Metaverse
							</h1>

							<p className="wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
                Bring your Real-World items into the Metaverse as NFTs!<br/>
                Use those NFTs to unlock exclusive member benefits!<br/>
                Show-off your authentic collection in both worlds!
							</p>

							<div className="button-store wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.6s">

								<a href="/#physical-first" className="js-scroll-trigger custom-btn d-inline-flex align-items-center m-2 m-sm-0 me-sm-3">
									<i className="fas fa-atom"></i><p>Start with<span>Physicals</span></p>
								</a>

								<a href="/#digital-first" className="js-scroll-trigger custom-btn d-inline-flex align-items-center m-2 m-sm-0">
									<i className="fas fa-microchip"></i><p>Start with<span>Digitals</span></p>
								</a>

							</div>

						</div>

					</Col>

					{/* <!-- Image --> */}
					<Col className="col-12 col-lg-6">

						<div className="banner-image wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
							<img className="bounce-effect" src={SingleWelcome} alt="" />
						</div>

					</Col>

				</Row>

			</Container>

			{/* <!-- Wave effect --> */}
			<div className="wave-effect wave-anim">

                <div className="waves-shape shape-one">
                    <div className="wave wave-one"></div>
                </div>

                <div className="waves-shape shape-two">
                    <div className="wave wave-two"></div>
                </div>

                <div className="waves-shape shape-three">
                    <div className="wave wave-three"></div>
                </div>

            </div>

		</section>
    );
}

export default SingleImage;